<template>
  <div>
    <ca-card>
      <template #body>
        <div class="col-12 mb-4 pl-0">
          <div class="row">
            <div class="col-12 col-md-6">
              <h4>{{ getHeading }}</h4>
            </div>
            <div class="col-12 col-md-6 d-flex flex-column">
              <SignedDownloadLink
                :file="latestContractDocument"
                class="pl-0"
              >
                <template #default="{link}">
                  <a :href="link"><i class="fas fa-file-pdf" /> Dokument herunterladen</a>
                </template>
              </SignedDownloadLink>
            </div>
          </div>

          <div
            v-if="order.ablehnungGrund"
            class="row"
          >
            <div class="col-12 col-md-6">
              Ablehnungsgrund
            </div>
            <div class="col-12 col-md-6">
              {{ translateAblehnungsgruende(order.ablehnungGrund) }}
            </div>
          </div>
          <div
            v-if="$can('seeSanctionCheckResults') && order.sanctions && order.sanctions.status"
            class="row"
          >
            <div class="col-12 col-md-6">
              Sanktionslistenprüfung
            </div>
            <div class="col-12 col-md-6">
              {{ sanctionStatus[order.sanctions.status].human(order.sanctions.handledBy ? `${order.sanctions.handledBy.firstname} ${order.sanctions.handledBy.lastname}`: null) }}
            </div>
          </div>
          <div
            v-if="order.nachtragGueltigAb"
            class="row"
          >
            <div class="col-12 col-md-6">
              Nachtrag gültig ab
            </div>
            <div class="col-12 col-md-6">
              {{ formatDate(order.nachtragGueltigAb) }}
            </div>
          </div>
        </div>
        <HSSOrderData
          :customer="customer"
          :order="order"
        />
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'

import ablehnungsgruende from '@/resources/enums/ablehnungsgruende.js'
import hsOrderTypes from '@/resources/enums/hsOrderTypes.js'
import sanctionStatus from '@/resources/enums/sanctionStatus.js'

import formatOrderStatus from '@/filters/formatOrderStatus'
import formatDate from '@/filters/formatDate'
import origins from '@/resources/enums/origins'
import HSSOrderData from '@/components/HSSOrderData.vue'

export default {
  components: {
    CaCard,
    SignedDownloadLink,
    HSSOrderData
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      customerCountry: null,
      hsOrderTypes,
      origins,
      sanctionStatus
    }
  },
  computed: {
    latestContractDocument () {
      // Gibt das letzte Dokument zurück, dass den Typ des Orders im Namen hat order ein Nachtrag ist. Das filtert dann SEPA und andere Dokumente raus.
      const contractDocuments = this.order.files.filter(file => file.name.includes(hsOrderTypes[this.order.hsOrderType].human) || file.name.includes('Nachtrag') || file.name.includes('Aufhebungsnachtrag') || file.name.includes('Ruhenachtrag'))
      return contractDocuments[contractDocuments.length - 1]
    },
    getHeading () {
      if (!this.order || (!this.order.hsOrderType && this.order.hsOrderType !== 0) || !this.order.number) return ''
      return `${formatOrderStatus(this.order.hsOrderType)} ${this.order.number} ${this.order.hsOrderStatus}`
    }
  },
  methods: {
    formatOrderStatus,
    formatDate,
    translateAblehnungsgruende (api) {
      return Object.values(ablehnungsgruende).find(grund => grund.api === api).human
    }
  }
}
</script>
