export default {
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      if (city.zip) {
        this.customer.address.PLZ = city.zip
      }
      if (city.name) {
        this.customer.address.ORT = city.name
      }
    },
    zipInputFormatter (city) {
      if (typeof city === 'object') {
        return city.zip
      }
      return city
    },
    cityInputFormatter (city) {
      if (typeof city === 'object') {
        return city.name
      }
      return city
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10, char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      if (typeof country !== 'object') return
      this.country = {
        ...country
      }
      this.customer.address.NAT = country._id
    },
    unsetCountry () {
      this.country = { char: null, name: null }
      this.customer.address.NAT = null
    },
    countryInputFormatter (country) {
      if (typeof country === 'object') {
        return country.char
      }
      return country
    }
  }
}
