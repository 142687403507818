<template>
  <Modal
    title="Anfrage ablehnen"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <BasicSelect
          v-model="ablehnungsgrund"
          label="Ablehnungsgrund"
          :options="ablehnungsgruendeOptions"
          required
        />
        <BasicTextarea
          v-if="ablehnungsgrund === ablehnungsgruende.Sonstiges.api"
          v-model="beschreibung"
          label="Beschreibung"
          required
        />
        <basic-checkbox
          v-if="true"
          v-model="neuabschlussSperren"
          label="Neuabschluss für diesen Kunden Sperren"
        />
        <p
          v-if="errorMessage"
          class="text-danger text-right mb-0"
        >
          {{ errorMessage }}
        </p>
        <div class="form-row justify-content-end">
          <button
            type="button"
            class="btn btn-orange"
            @click="declineAnfrage"
          >
            Anfrage ablehnen
          </button>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import BasicSelect from '../BaseComponents/BasicSelect.vue'
import ablehnungsgruende from '@/resources/enums/ablehnungsgruende'
import BasicTextarea from '../BaseComponents/BasicTextarea.vue'
import BasicCheckbox from '../BaseComponents/BasicCheckbox.vue'
import feathersClient from '@/api'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicSelect,
    BasicTextarea,
    BasicCheckbox
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ablehnungsgrund: null,
      beschreibung: null,
      ablehnungsgruende,
      neuabschlussSperren: false,
      errorMessage: null
    }
  },
  computed: {
    ablehnungsgruendeOptions () {
      return Object.values(ablehnungsgruende).map(grund => ({ value: grund.api, label: grund.human }))
    }
  },
  methods: {
    async declineAnfrage () {
      this.errorMessage = null
      if (!await this.$validator.validateAll()) return
      try {
        await feathersClient.service('hs-order-process-anfrage-ablehnen/create').create({
          hsOrderId: this.orderId,
          ablehnungsgrund: this.ablehnungsgrund,
          beschreibung: this.beschreibung
        })

        if (this.neuabschlussSperren) {
          await this.customerNeuabschlussSperren()
        }
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.errorMessage = 'Fehler beim ablehnen der Anfrage'
      }
    },
    async customerNeuabschlussSperren () {
      this.errorMessage = null
      try {
        const customerId = (await feathersClient.service('hs-order').get(this.orderId)).customerId
        await feathersClient.service('customer').patch(customerId, { neuabschlussGesperrt: true })
      } catch (e) {
        console.error(e)
        this.errorMessage = 'Fehler beim bearbeiten des Kundes'
      }
    }
  }
}
</script>
