module.exports = {
  1: { api: 1, human: 'Normales Kennzeichen', gdv: 'Z' },
  2: { api: 2, human: 'Historisches Kennzeichen', gdv: 'Z' },
  3: { api: 3, human: 'Saisonkennzeichen', gdv: 'ZS', isSaisonkennzeichen: true },
  4: { api: 4, human: 'Historisches Saisonkennzeichen', gdv: 'ZS', isSaisonkennzeichen: true },
  5: { api: 5, human: 'Wechselkennzeichen', gdv: 'Z', restrictToScenarioIds: ['seeInternalKennzeichen'] },
  6: { api: 6, human: 'Kurzzeitkennzeichen (Überführungskennzeichen)', gdv: 'RK', restrictToScenarioIds: ['seeInternalKennzeichen'] },
  7: { api: 7, human: 'Rotes Dauerkennzeichen 07er', gdv: 'R' },
  8: { api: 8, human: 'Rotes Dauerkennzeichen 06er', gdv: 'R', restrictToScenarioIds: ['seeInternalKennzeichen'] },
  9: { api: 9, human: 'Grünes Kennzeichen', gdv: 'Z' },
  10: { api: 10, human: 'Ohne Kennzeichen' },
  11: { api: 11, human: 'Ausländisches Kennzeichen', restrictToScenarioIds: ['seeInternalKennzeichen'] },
  12: { api: 12, human: 'E-Kennzeichen', gdv: 'Z' },
  13: { api: 13, human: 'Sonstiges', restrictToScenarioIds: ['seeInternalKennzeichen'] },
  14: { api: 14, human: 'Versicherungskennzeichen', gdv: 'V', restrictToScenarioIds: ['seeInternalKennzeichen'] },
  15: { api: 15, human: 'Rotes Versicherungskennzeichen ', gdv: 'VR', restrictToScenarioIds: ['seeInternalKennzeichen'] }
}
// gdv nach GDV Gesamtverfahrenshandbuch Seite 1523
