<template>
  <Card>
    <template #header>
      <div class="row">
        <div class="col">
          <h4 class="mb-0">
            GDV-Fehlermeldungen
          </h4>
        </div>
      </div>
    </template>
    <template #default>
      <div
        v-for="fehlermeldung in order.gdvFehlermeldungen"
        :key="fehlermeldung._id"
        class="row px-3 py-2 align-items-center"
      >
        <div
          v-if="fehlermeldung.erledigt"
          class="col-auto pr-0"
        >
          <i class="far fa-check-circle fa-lg text-success" />
        </div>
        <div
          v-else
          class="col-auto pr-0"
        >
          <i class="far fa-exclamation-circle fa-lg text-danger" />
        </div>
        <div class="col align-items-center">
          {{ fehlermeldung.errorMessage }}
        </div>
        <div
          v-if="!fehlermeldung.erledigt"
          class="col-auto"
        >
          <button
            class="btn btn-primary text-sm"
            @click="resolveError(fehlermeldung._id)"
          >
            Als erledigt markieren
          </button>
        </div>
        <hr>
      </div>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/Card.vue'
import feathers from '@/api'
export default {
  components: {
    Card
  },
  props: {
    order: {
      type: Object,
      default: () => Object
    }
  },
  methods: {
    async resolveError (id) {
      const fehlermeldung = this.order.gdvFehlermeldungen.find(fehlermeldung => fehlermeldung._id === id)
      fehlermeldung.erledigt = true
      const params = {
        query: {
          $client: { noPDF: true }
        }
      }
      feathers.service('hs-order').patch(this.order._id, this.order, params)
    }
  }

}
</script>
