<template>
  <div>
    <button
      v-if="$can('processStorno') && order.hsOrderType === hsOrderTypes.VERTRAG.api && (order.hsOrderStatus === hsOrderStatus.AKTIV.api || order.hsOrderStatus === hsOrderStatus.RUHEND.api)"
      class="btn btn-orange text-white mr-2"
      type="button"
      :disabled="isNachtragLocked(order)"
      @click="showStornoModal = true"
    >
      Vertrag stornieren
    </button>
    <button
      v-if="$can('processVertragAusStorno') && order.hsOrderType === hsOrderTypes.VERTRAG.api && (order.hsOrderStatus === hsOrderStatus.STORNO_VORGEMERKT.api || order.hsOrderStatus === hsOrderStatus.STORNIERT.api) && stornogruende[order.stornoGrund].isReaktivierbar && (!order.stornoBearbeitetAm || dayjs().isBefore(dayjs(order.stornoBearbeitetAm).startOf('day').add(2, 'months')))"
      class="btn btn-primary text-white mr-2"
      type="button"
      :disabled="isNachtragLocked(order)"
      @click="showVertragAusStornoModal = true"
    >
      Storno widerrufen
    </button>
    <button
      v-if="$can('processVertragAbschrift') && order.hsOrderType === hsOrderTypes.VERTRAG.api"
      class="btn btn-primary text-white mr-2"
      type="button"
      :disabled="isNachtragLocked(order)"
      @click="showVertragAbschriftModal = true"
    >
      Abschrift erzeugen
    </button>
    <button
      v-if="$can('processRuhesetzung') && order.hsOrderStatus === hsOrderStatus.AKTIV.api"
      class="btn btn-orange text-white mr-2"
      type="button"
      :disabled="isNachtragLocked(order)"
      @click="showRuhesetzungModal = true"
    >
      Vertrag in Ruhe setzen
    </button>
    <button
      v-if="$can('processStorno') && order.hsOrderStatus === hsOrderStatus.RUHEND.api"
      class="btn btn-primary text-white mr-2"
      type="button"
      :disabled="isNachtragLocked(order)"
      @click="showVertragAusRuheModal = true"
    >
      Wiederinkraftsetzung
    </button>
    <button
      v-if="$can('processStorno')"
      class="btn btn-primary text-white mr-2"
      type="button"
      @click="showEvbModal = true"
    >
      eVB Erzeugen
    </button>
    <VertragRuhesetzungModal
      v-if="showRuhesetzungModal"
      :order-id="order._id"
      @close="showRuhesetzungModal = false"
    />
    <VertragAusRuheModal
      v-if="showVertragAusRuheModal"
      :order-id="order._id"
      @close="showVertragAusRuheModal = false"
    />
    <VertragStornierenModal
      v-if="showStornoModal"
      :order-id="order._id"
      @close="showStornoModal = false"
    />
    <VertragAusStornoModal
      v-if="showVertragAusStornoModal"
      :order-id="order._id"
      @close="showVertragAusStornoModal = false"
    />
    <EvbModal
      v-if="showEvbModal"
      :order="order"
      :customer="customer"
      @close="showEvbModal = false"
    />
    <VertragAbschriftModal
      v-if="showVertragAbschriftModal"
      :order="order"
      @close="showVertragAbschriftModal = false"
    />
  </div>
</template>
<script>
import VertragStornierenModal from '../Modals/VertragStornierenModal.vue'
import hsOrderStatus from '@/resources/enums/hsOrderStatus.js'
import hsOrderTypes from '@/resources/enums/hsOrderTypes.js'
import VertragRuhesetzungModal from '../Modals/VertragRuhesetzungModal.vue'
import VertragAusRuheModal from '../Modals/VertragAusRuheModal.vue'
import VertragAusStornoModal from '../Modals/VertragAusStornoModal.vue'
import stornogruende from '@/resources/enums/stornogruende'
import dayjs from 'dayjs'
import EvbModal from '../Modals/EvbModal.vue'
import VertragAbschriftModal from '../Modals/VertragAbschriftModal.vue'
import isNachtragLocked from '@/resources/helpers/isNachtragLocked'

export default {
  components: { VertragStornierenModal, VertragAusStornoModal, VertragRuhesetzungModal, VertragAusRuheModal, VertragAbschriftModal, EvbModal },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showVertragAusStornoModal: false,
      showVertragAbschriftModal: false,
      showStornoModal: false,
      showRuhesetzungModal: false,
      showVertragAusRuheModal: false,
      showEvbModal: false,
      hsOrderStatus,
      hsOrderTypes,
      stornogruende,
      dayjs,
      isNachtragLocked
    }
  },
  computed: {
  }
}
</script>
