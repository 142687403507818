module.exports = {
  1: { api: 1, human: 'Januar' },
  2: { api: 2, human: 'Februar' },
  3: { api: 3, human: 'März' },
  4: { api: 4, human: 'April' },
  5: { api: 5, human: 'Mai' },
  6: { api: 6, human: 'Juni' },
  7: { api: 7, human: 'Juli' },
  8: { api: 8, human: 'August' },
  9: { api: 9, human: 'September' },
  10: { api: 10, human: 'Oktober' },
  11: { api: 11, human: 'November' },
  12: { api: 12, human: 'Dezember' }
}
