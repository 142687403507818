<template>
  <Modal
    title="Angebot ablehnen"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <p>Wollen Sie dieses Angebot wirklich ablehnen?</p>
        <div class="form-row justify-content-end">
          <button
            type="button"
            class="btn btn-secondary mr-2"
            @click="$emit('close')"
          >
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-orange"
            @click="declineAngebot"
          >
            Angebot ablehnen
          </button>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'

export default {
  components: {
    Modal
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
    }
  },
  methods: {
    async declineAngebot () {
      await feathersClient.service('hs-order-process-angebot-ablehnen/create').create({
        hsOrderId: this.orderId
      })
      this.$emit('close')
    }
  }
}
</script>
