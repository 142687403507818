<template>
  <div>
    <router-link
      class="btn btn-primary text-white mr-2"
      tag="button"
      :to="{ name: 'HSSOrderAntragEdit', params: { orderId: orderId } }"
    >
      <i class="far fa-pen" />Antrag abschließen
    </router-link>
    <button
      v-if="$can('orderAblaufenLassen')"
      class="btn btn-orange text-white mr-2"
      type="button"
      @click="showAbgelaufenModal = true"
    >
      Antrag ablaufen lassen
    </button>
    <OrderAbgelaufenModal
      v-if="showAbgelaufenModal"
      title="Antrag ablaufen lassen"
      message="Wollen Sie diesen Antrag wirklich ablaufen lassen?"
      button-text="Antrag ablaufen lassen"
      :order-id="orderId"
      @close="showAbgelaufenModal = false"
    />
  </div>
</template>
<script>
import OrderAbgelaufenModal from '../Modals/OrderAbgelaufenModal.vue'
export default {
  components: { OrderAbgelaufenModal },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showAbgelaufenModal: false
    }
  }
}
</script>
