<template>
  <div>
    <BasicSelect
      v-model="saisonkennzeichenvonLocal"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      :horizontal="true"
      label="Saison von"
      placeholder="Bitte wählen"
      :options="allMonths"
      required
      @input="$emit('update:saisonkennzeichenvon', $event)"
    />
    <BasicSelect
      v-model="saisonkennzeichenbisLocal"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      :horizontal="true"
      label="Saison bis"
      placeholder="Bitte wählen"
      :options="filteredMonths"
      required
      @input="$emit('update:saisonkennzeichenbis', $event)"
    />
  </div>
</template>

<script>
import months from '@/resources/enums/months'
import BasicSelect from '../../BaseComponents/BasicSelect.vue'
export default {
  components: {
    BasicSelect
  },
  props: {
    saisonkennzeichenvon: {
      type: Number,
      default: undefined
    },
    saisonkennzeichenbis: {
      type: Number,
      default: undefined
    }
  },
  data () {
    return {
      saisonkennzeichenvonLocal: undefined,
      saisonkennzeichenbisLocal: undefined
    }
  },
  computed: {
    allMonths () {
      return Object.values(months).map(month => ({ label: month.human, value: month.api }))
    },
    filteredMonths () {
      // Saisonbeginn = 1. des gewählten Monats
      // Saisonende = 30. des gewählten Monats
      // Saison muss mindestens 2 Monate und maximal 11 Monate dauern
      // => Saison darf nicht von Monat bis Vormonat sein (1 Jahr) oder von Monat bis Monat (1 Monat bzw. 13 Monate)
      if (!this.saisonkennzeichenvonLocal) return this.allMonths
      const copyMonths = [...this.allMonths]
      if (this.saisonkennzeichenvonLocal === 0) {
        copyMonths.pop() // Wenn Januar gewählt, entferne Dezember
      } else {
        copyMonths.splice(this.saisonkennzeichenvonLocal - 2, 1) // Entferne Vormonat
      }
      const indexForbiddenMonth = copyMonths.findIndex(month => month.value === this.saisonkennzeichenvonLocal)
      copyMonths.splice(indexForbiddenMonth, 1) // Entferne gewählten Monat
      return copyMonths
    }
  },
  watch: {
    saisonkennzeichenvon: {
      handler: function () {
        this.saisonkennzeichenvonLocal = this.saisonkennzeichenvon
      },
      immediate: true
    },
    saisonkennzeichenbis: {
      handler: function () {
        this.saisonkennzeichenbisLocal = this.saisonkennzeichenbis
      },
      immediate: true
    }
  }
}
</script>
