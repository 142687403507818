<template>
  <basic-form-group
    :id="id"
    :label-is-up="labelIsUp"
    :label="$attrs.label"
    :required="$attrs.required"
    :horizontal="$attrs.horizontal"
    :columns="$attrs.columns"
    :margin="$attrs.margin"
    :small="$attrs.small"
    :infotext="$attrs.infotext"
    :align-label-center="$attrs.alignLabelCenter"
  >
    <template slot="label">
      <slot name="label" />
    </template>
    <template slot="input">
      <div>
        <b-form-radio-group
          :id="id"
          v-model="model"
          v-validate="getValidation"
          :class="{'is-invalid': veeFields[veeField] && veeFields[veeField].validated && veeFields[veeField].invalid}"
          :name="name"
          :data-vv-as="name"
          v-bind="$attrs"
          :options="options"
          required
          class="custom-radio"
          v-on="listeners"
        />
      </div>
      <validation-feedback :name="veeField" />
    </template>
  </basic-form-group>
</template>

<script>
import basicComponentMixin from './basic-component-mixin.js'
export default {
  name: 'BasicRadio',
  mixins: [basicComponentMixin],
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    }
  }
}
</script>
