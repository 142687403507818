<template>
  <Card class="">
    <template #header>
      <h4 class="p-0 m-0">
        Kommentare
      </h4>
    </template>
    <template #default>
      <div
        v-if="!loading"
        class="p-3"
      >
        <h6
          v-if="!comments || comments.length === 0"
        >
          Es gibt noch keine Kommentare
        </h6>

        <div
          v-if="showLoadMore"
          class="container text-center mb-3 load-more"
        >
          <button
            class="btn hover-secondary text-primary"
            @click="loadMore"
          >
            - Mehr Kommentare anzeigen -
          </button>
        </div>
        <transition-group
          name="slide-fade"
          tag="div"
        >
          <div
            v-for="(comment, index) in comments"
            :key="index+'comment'"
            class="comments"
          >
            <div class="pb-2">
              <p class="d-inline-block font-weight-bold small ">
                {{ comment.user.firstname }} {{ comment.user.lastname }}
              </p>
              <p class="d-inline text-gray-4 small mb-0">
                - {{ formatTime(comment.createdAt) }}
              </p>
              <p
                v-if="comment.isIntern"
                class="d-inline text-gray-4 small mb-0"
              >
                - Intern
              </p>
              <!-- eslint-disable vue/no-v-html -->
              <div
                class="bg-gray-1 rounded p-3 mt-1 mb-2"
                v-html="comment.text"
              />
              <template
                v-for="orderDocument in comment.orderDocuments"
              >
                <SignedDownloadLink
                  :key="orderDocument._id"
                  :file="orderDocument.file"
                >
                  <template #default="{link}">
                    <span class="badge badge-secondary font-weight-normal badge-pill px-2 py-1">
                      <a
                        :href="link"
                        :download="orderDocument.file.name"
                      ><i class="far fa-file-pdf" /> {{ orderDocument.file.name }}</a>
                    </span>
                  </template>
                </SignedDownloadLink>
              </template>
            </div>
            <hr class="m-0">
          </div>
        </transition-group>

        <div class="mt-2">
          <button
            v-if="!commentModus"
            class="btn btn-primary"
            @click="enableCommentModus()"
          >
            <i class="far fa-comment-lines" /> Kommentar
          </button>
          <div
            v-if="commentModus"
          >
            <transition name="slide-fade">
              <ca-alert
                v-if="!!saveErrorMessage"
                class="mb-2"
                variant="danger"
                :message="saveErrorMessage"
                @close="saveErrorMessage = null"
              />
            </transition>
            <BasicWysiwyg
              v-if="commentModus"
              v-model="newComment"
              class="mb-4"
              :file-upload="true"
              @updateFiles="updateFiles"
            />
            <div
              class="w-100 d-flex align-items-center"
              :class="{ 'justify-content-between': $can('commentsIntern'), 'justify-content-end': $can('commentsIntern') }"
            >
              <BasicCheckbox
                v-if="$can('commentsIntern')"
                v-model="isIntern"
                label="Interner Kommentar (nur für Admins sichtbar)"
              />
              <button
                class="btn btn-primary"
                :disabled="sendPending"
                type="button"
                @click="send()"
              >
                Abschicken
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import BasicWysiwyg from './BaseComponents/BasicWysiwyg.vue'
import feathers from '../api'
import SignedDownloadLink from '@/components/SignedDownloadLink'
import formatDate from '@/filters/formatDate.js'
import moment from 'moment'
import CaAlert from '@/components/Alert.vue'
import BasicCheckbox from './BaseComponents/BasicCheckbox.vue'
import Card from './Card.vue'

export default {
  components: {
    SignedDownloadLink,
    BasicWysiwyg,
    CaAlert,
    BasicCheckbox,
    Card
  },
  filters: {
    formatDate
  },
  props: {
    entityId: {
      default: null,
      type: String
    },
    entity: {
      default: null,
      type: String
    }
  },
  data () {
    return {
      loading: true,
      sendPending: false,
      comments: [],
      elementsLoaded: 0,
      totalComments: 0,
      files: [],
      commentModus: false,
      newComment: '',
      saveErrorMessage: null,
      isIntern: false
    }
  },
  computed: {
    showLoadMore () {
      return this.totalComments > 5 && this.totalComments > this.elementsLoaded
    },
    me () {
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    enableCommentModus () {
      if (this.$can('commentsIntern')) {
        this.isIntern = true
      }
      this.commentModus = true
    },
    formatTime (myDate) {
      const today = moment()
      if (moment(myDate).isSame(today, 'day')) {
        return `Heute ${formatDate(myDate, 'HH:mm')}`
      } else {
        return formatDate(myDate, 'DD.MM.YYYY HH:mm')
      }
    },
    async fetchData () {
      this.loading = true
      try {
        const query = {
          entityId: this.entityId,
          $limit: 5,
          $sort: {
            createdAt: -1
          }
        }
        if (!this.$can('commentsIntern')) {
          query.isIntern = false
        }
        const comments = (await this.$store.dispatch('comment/find', {
          query: {
            ...query
          }
        }))
        this.comments = comments.data.reverse()
        this.totalComments = comments.total
        this.elementsLoaded = comments.data.length
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async loadMore () {
      try {
        const query = {
          entityId: this.entityId,
          $skip: this.elementsLoaded,
          $limit: 5,
          $sort: {
            createdAt: -1
          }
        }
        if (!this.$can('commentsIntern')) {
          query.isIntern = false
        }
        const comments = (await this.$store.dispatch('comment/find', {
          query: {
            ...query
          }
        }))
        this.comments.unshift(...(comments.data.reverse()))
        this.totalComments = comments.total
        this.elementsLoaded += comments.data.length
      } catch (error) {
        console.error(error)
      }
    },
    async send () {
      this.saveErrorMessage = null
      if (!this.newComment) {
        this.saveErrorMessage = 'Kommentar darf nicht leer sein!'
        return
      }
      this.sendPending = true
      try {
        const orderDocumentIds = await Promise.all(this.files.map(async (file) => {
          const orderDocument = await feathers.service('order-document').create({
            orderId: this.entityId,
            type: 'COMMENT',
            fileId: file
          })
          return orderDocument._id
        }))
        const newComment = await this.$store.dispatch('comment/create', {
          text: this.newComment,
          userId: this.me._id,
          entityId: this.entityId,
          entity: this.entity,
          isIntern: this.isIntern,
          orderDocumentIds
        })
        this.newComment = ''
        this.isIntern = false
        this.comments.push(newComment)
        this.files = []
      } catch (error) {
        console.error(error)
        this.saveErrorMessage = error.message
        return
      } finally {
        this.sendPending = false
        this.commentModus = false
      }
    },
    updateFiles (files) {
      this.files = [...files]
    }
  }
}
</script>
<style scoped lang="scss">
.load-more {
  color: inherit !important;

  a:hover {
    text-decoration: underline !important;
  }
}
.editor-content ::v-deep{
  div{
    min-height: 100px;
    padding: 10px;
    p{
      margin-bottom: 0;
    }
  }
}
.comments ::v-deep{
  p, ul{
    margin-bottom: 0;
  }
}
</style>
