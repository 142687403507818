const dayjs = require('dayjs')

module.exports = function isNachtragLocked (hsOrder) {
  if (!hsOrder.nachtragCreatedAt) return false
  // Wenn seit dem lezten Nachtrag der Nachtrag entsperrt wurde, ist erneut ein Nachtrag möglich
  if (hsOrder.nachtragUnlockedAt && new Date(hsOrder.nachtragUnlockedAt) > new Date(hsOrder.nachtragCreatedAt)) return false
  // Die Erstellung eines Nachtrags ist für 7 Tage nach einem Nachtrag gesperrt
  const nachtragLockedTill = dayjs(hsOrder.nachtragCreatedAt).startOf('day').add(7, 'days')
  const today = dayjs().startOf('day')
  return nachtragLockedTill.isAfter(today)
}
