<template>
  <Modal
    title="Storno widerrufen "
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <div class="form-row justify-content-end">
          <p
            v-if="error"
            class="text-danger w-100 text-right"
          >
            {{ error }}
          </p>
          <div>
            <button
              type="button"
              class="btn btn-secondary mr-2"
              @click="$emit('close')"
            >
              Abbrechen
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="loading"
              @click="cancelStorno"
            >
              <i
                v-if="loading"
                class="fad fa-circle-notch fa-spin"
              />
              Storno widerrufen
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'
import stornogruende from '@/resources/enums/stornogruende.js'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      error: null
    }
  },
  computed: {
    stornogruendeOptions () {
      return Object.values(stornogruende).map(grund => ({ value: grund.api, label: grund.human }))
    }
  },
  methods: {
    async cancelStorno () {
      this.loading = true
      this.error = null
      try {
        await feathersClient.service('hs-order-process-vertrag-aus-storno').create({
          hsOrderId: this.orderId
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
