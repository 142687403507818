<template>
  <div>
    <template v-if="tableData.orderAsTableData">
      <div
        v-for="category in Object.values(tableData.orderAsTableData)"
        :key="`category`+category.label"
        class="col-12 mb-4 pl-0"
      >
        <h4 class="col-12 pl-0">
          {{ category.label }}
          <router-link
            v-if="category.linkVerwaltung"
            :to="{name: category.linkVerwaltung.name, params: {...category.linkVerwaltung.params} }"
          >
            <i class="far fa-external-link" />
          </router-link>
        </h4>
        <template v-if="category.data">
          <div
            v-for="field in Object.values(category.data).filter(field => field.value)"
            :key="`category-data+${field.label}`"
            class="row"
          >
            <div class="col-12 col-md-6">
              {{ field.label }}
            </div>
            <div class="col-12 col-md-6">
              {{ field.value }}
            </div>
          </div>
        </template>
      </div>
    </template>
    <div
      v-if="calculationData"
      class="col-12 mb-4 pl-0"
    >
      <h4>{{ calculationData.label }}</h4>
      <div
        v-for="field in Object.values(calculationData.data).filter(field => field.value)"
        :key="`calculation-data+${field.label}`"
        class="mb-2"
        :class="{'border-top border-dark': field.labelClass && field.labelClass.includes('borderTop')}"
      >
        <div class="row">
          <div class="col-12 col-md-8">
            <div :class="{'font-weight-bold': field.labelClass && field.labelClass.includes('bold')}">
              {{ field.label }}
            </div>
            <div><small class="align-top">{{ field.subText }}</small></div>
          </div>
          <div class="col-12 col-md-4 text-right">
            {{ field.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import convertOrderToTableData from '@/resources/helpers/convertOrderToTableData.js'
import feathersClient from '@/api'
import { cloneDeep } from 'lodash'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {

  },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    },
    result: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      tableData: {}
    }
  },
  computed: {
    calculationData () {
      return this.tableData.calculationData
    }
  },
  async created () {
    const order = cloneDeep(this.order)
    if (!order.calculatedData) order.calculatedData = this.result
    this.tableData = await convertOrderToTableData(order, this.customer, feathersClient)
  }
}
</script>
