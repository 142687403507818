<template>
  <CaCard class="mb-3">
    <template #header>
      <h4 class="mb-0">
        {{ heading ? heading : 'Allgemein' }}
      </h4>
    </template>
    <template #body>
      <form
        v-if="customer.name"
        novalidate
        autocomplete="off"
        @submit.prevent
      >
        <BasicRadio
          v-model="customer.kind"
          :options="humanCustomerKindOptions"
          required
          :disabled="!isNewCustomer"
        />
        <div
          v-if="!isCompany"
          class="form-row my-3"
        >
          <div class="col-12 col-md-6">
            <BasicSelect
              id="title-gen"
              v-model="customer.name.ANR"
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="Anrede"
              :horizontal="true"
              :margin="false"
              required
              label="Anrede"
              :options="humanGenOptions"
            />
          </div>
          <div class="col-12 col-md-3">
            <BasicSelect
              id="title-tit"
              v-model="customer.name.TI1"
              :margin="false"
              placeholder="Akademischer Titel"
              :options="humanTitOptions"
            />
          </div>
          <div class="col-12 col-md-3">
            <BasicSelect
              id="title-pre"
              v-model="customer.name.TI2"
              :margin="false"
              placeholder="Adelstitel"
              :options="humanPreOptions"
            />
          </div>
        </div>

        <template v-if="!isCompany">
          <BasicInput
            id="lastname"
            v-model="customer.name.NAM"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Nachname"
            :horizontal="true"
            label="Nachname"
            required
          />
          <BasicInput
            id="firstname"
            v-model="customer.name.VOR"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Vorname"
            :horizontal="true"
            label="Vorname"
            required
          />
        </template>

        <template v-else>
          <BasicInput
            v-model="customer.name.NAM"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Firmenname"
            :horizontal="true"
            label="Firma"
            required
          />
          <BasicInput
            v-model="customer.address.ZUSATZ1"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Adresszeile 1"
            :horizontal="true"
            label="Addresszeile 1"
          />
          <BasicInput
            v-model="customer.address.ZUSATZ2"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Adresszeile 2"
            :horizontal="true"
            label="Adresszeile 2"
          />
          <BasicInput
            v-model="customer.address.ZUSATZ3"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="Adresszeile 3"
            :horizontal="true"
            label="Adresszeile 3"
          />
        </template>

        <BasicInput
          id="street"
          v-model="customer.address.STR"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Straße, Nr."
          :horizontal="true"
          label="Straße, Nr."
          required
        />

        <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <BasicTypeahead
              id="wioejfijwief"
              v-model="customer.address.PLZ"
              auto-select-first
              :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
              placeholder="PLZ"
              :query="searchCityByZip"
              :input-formatter="zipInputFormatter"
              :result-formatter="cityResultFormatter"
              :horizontal="true"
              label="PLZ / Ort"
              :margin="false"
              required
              strict
              @hit="setCity"
            />
          </div>
          <div class="col-12 col-md-6">
            <BasicTypeahead
              id="foiawfiuhfiw"
              v-model="customer.address.ORT"
              auto-select-first
              autocomplete="off"
              :margin="false"
              :query="searchCityByName"
              :input-formatter="cityInputFormatter"
              :result-formatter="cityResultFormatter"
              required
              placeholder="Ort"
              strict
              @hit="setCity"
            />
          </div>
        </div>
        <!-- <div class="form-row my-3">
          <div class="col-12 col-md-6">
            <form
              autocomplete="off"
              @submit.prevent
            >
              <BasicTypeahead
                id="country-char"
                v-model="country.char"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                placeholder="Land"
                :horizontal="true"
                label="Land"
                :margin="false"
                :query="searchCountry"
                :input-formatter="countryInputFormatter"
                :result-formatter="countryResultFormatter"
                strict
                @hit="setCountry"
                @unselect="unsetCountry"
              />
            </form>
          </div>
          <div class="col-12 col-md-6">
            <BasicInput
              id="country-name"
              v-model="country.name"
              :margin="false"
              placeholder="Land"
              disabled
            />
          </div>
        </div> -->

        <hr>

        <template v-if="!isCompany">
          <BasicDatepicker
            id="gebdat"
            v-model="customer.misc.GEBDAT"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            placeholder="TT.MM.JJJJ"
            :horizontal="true"
            label="Geboren am"
            :margin="false"
            :required="!isCompany"
            :disabled-dates="disabledDates"
            custom-error-message="Versicherungsnehmer muss volljährig sein"
          />
        </template>

        <!-- <BasicSelect
          id="stat"
          v-model="customer.misc.STA"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bitte auswählen..."
          :horizontal="true"
          label="Status"
          required
          :options="humanStatusOptions"
        /> -->
        <template v-if="!isCompany">
          <hr>

          <div class="form-row py-2">
            <div class="col-12 col-md-4">
              Ist verstorben
            </div>
            <div class="col-12 col-md-8 d-flex align-items-center">
              <BasicCheckbox
                v-model="isDead"
                :margin="false"
              />
            </div>
          </div>

          <b-collapse
            id="isDead"
            v-model="isDead"
          >
            <BasicDatepicker
              id="birthday"
              v-model="customer.misc.VERSTORBAM"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="TT.MM.JJJJ"
              :horizontal="true"
              label="Verstorben am"
              :required="isDead"
            />
          </b-collapse>
        </template>
        <hr>

        <!-- <BasicSelect
          id="klasse"
          v-model="customer.misc.KLASSE"
          :margin="false"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bitte auswählen..."
          :horizontal="true"
          label="Klasse"
          required
          :options="humanClassOptions"
        /> -->
      </form>
    </template>
  </CaCard>
</template>

<script>
import CaCard from '@/components/Card.vue'
import TitleSelectMixin from '@/components/Forms/title-select-mixin.js'
import AddressTypeaheadMixin from '@/components/Forms/Customer/address/address-typeahead-mixin.js'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import familienstand from '@/resources/enums/familienstand.js'
import status from '@/resources/enums/customerStatus.js'
import klasse from '@/resources/enums/customerClasses.js'
import moment from 'moment'
import customerKindEnum from '@/resources/enums/customerKindEnum.js'
import BasicRadio from '@/components/BaseComponents/BasicRadio.vue'

export default {
  components: {
    CaCard,
    BasicInput,
    BasicSelect,
    BasicTypeahead,
    BasicDatepicker,
    BasicCheckbox,
    BasicRadio
  },
  mixins: [TitleSelectMixin, AddressTypeaheadMixin],
  props: {
    customer: {
      type: Object,
      required: true
    },
    isNewCustomer: {
      type: Boolean,
      default: false
    },
    heading: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      country: {
        name: null,
        char: ''
      },
      isDead: false,
      familienstand,
      status,
      klasse
    }
  },
  computed: {
    isCompany () {
      return this.customer.name?.ANR === 'f' || this.customer.kind === 'FIRMA'
    },
    humanFamOptions () {
      const options = Object.values(this.familienstand).map(fam => ({ label: fam.human, value: fam.api }))
      return options
    },
    humanStatusOptions () {
      const options = Object.values(this.status).map(status => ({ label: status.human, value: status.api }))
      return options
    },
    humanClassOptions () {
      const options = Object.values(this.klasse).map(klasse => ({ label: klasse.human, value: klasse.api }))
      return options
    },
    humanCustomerKindOptions () {
      return Object.values(customerKindEnum).map(kind => ({
        text: kind.human,
        value: kind.api
      }))
    },
    disabledDates () {
      return {
        from: new Date(moment().startOf('day').subtract(18, 'years'))
      }
    }
  }
}
</script>
