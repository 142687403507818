<template>
  <div>
    <span
      v-if="nachtragLocked"
      class="mr-2"
    >Der Nachtrag ist bis zum {{ formatDate(nachtragLockedTill) }} gesperrt</span>
    <router-link
      v-if="[hsOrderStatus.AKTIV.api, hsOrderStatus.RUHEND.api, hsOrderStatus.STORNO_VORGEMERKT.api].includes(order.hsOrderStatus) && order.hsOrderType === hsOrderTypes.VERTRAG.api"
      class="btn btn-primary text-white mr-2"
      tag="button"
      :disabled="nachtragLocked"
      :to="{ name: 'HSSOrderNachtrag', params: { orderId: order._id } }"
    >
      <i class="far fa-pen" /> Nachtrag erstellen
    </router-link>
    <button
      v-if="nachtragLocked && $can('processNachtragUnlock')"
      class="btn btn-primary text-white mr-2"
      @click="unlockNachtrag"
    >
      <i class="far fa-lock-open" /> Entsperren
    </button>
  </div>
</template>
<script>
import moment from 'moment'
import formatDate from '@/filters/formatDate'
import feathersClient from '@/api'
import hsOrderStatus from '@/resources/enums/hsOrderStatus'
import hsOrderTypes from '@/resources/enums/hsOrderTypes'

export default {
  props: {
    order: {
      type: Object,
      required: true
    },
    client: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showModal: false,
      hsOrderStatus,
      hsOrderTypes
    }
  },
  computed: {
    nachtragLockedTill () {
      return moment(this.order.nachtragCreatedAt).startOf('day').add(7, 'days')
    },
    nachtragLocked () {
      if (!this.order.nachtragCreatedAt) return false
      if (this.order.nachtragUnlockedAt && moment(this.order.nachtragUnlockedAt).isAfter(moment(this.order.nachtragCreatedAt))) return false
      // Das Erstellen eines Nachtrags ist gesperrt für 7 Tage nachdem ein Nachtrag erstellt wurde
      const today = moment().startOf('day')
      return !this.order.nachtragCreatedAt || this.nachtragLockedTill.isAfter(today)
    }
  },
  methods: {
    formatDate,
    async unlockNachtrag () {
      await feathersClient.service('hs-order-process-nachtrag-entsperren').create({
        hsOrderId: this.order._id
      })
      this.order.nachtragUnlockedAt = new Date()
    }
  }
}
</script>
