module.exports = {
  Fahrzeugart: { api: 'Fahrzeugart', human: 'Fahrzeugart' },
  BaulichVeraendert: { api: 'BaulichVerändert', human: 'Zustand: baulich verändert (getunt)' },
  BaulichGeplant: { api: 'BaulichGeplant', human: 'Zustand: bauliche Veränderung geplant' },
  VWertHoch: { api: 'VWertHoch', human: 'Versicherungswert zu hoch' },
  VWertNiedrig: { api: 'VWertNiedrig', human: 'Versicherungswert zu niedrig' },
  Chauffeurvermietung: { api: 'Chauffeurvermietung', human: 'Nutzung: Chauffeurvermietung' },
  Selbstfahrervermietung: { api: 'Selbstfahrervermietung', human: 'Nutzung: Selbstfahrervermietung' },
  SonstigeNutzung: { api: 'SonstigeNutzung', human: 'Nutzung: Sonstige Nutzung' },
  Kurzzeitkennzeichen: { api: 'Kurzzeitkennzeichen', human: 'Kennzeichenart: Kurzzeitkennzeichen' },
  RotesDauerkennzeichen07: { api: 'RotesDauerkennzeichen07', human: 'Kennzeichenart: Rotes Dauerkennzeichen 07' },
  OhneKennzeichen: { api: 'OhneKennzeichen', human: 'Kennzeichenart: ohne Kennzeichen' },
  EKennzeichen: { api: 'EKennzeichen', human: 'Kennzeichenart: E-Kennzeichen' },
  Sanktioniert: { api: 'Sanktioniert', human: 'Der Kunde befindet sich auf der Sanktionsliste der EU' },
  Sonstiges: { api: 'Sonstiges', human: 'Sonstiges' }
}
