<template>
  <div>
    <ca-card
      v-if="files && files.length > 0"
      class="mb-3"
    >
      <template #header>
        <div class="row">
          <div class="col">
            <h4 class="mb-0">
              {{ title }}
            </h4>
          </div>
        </div>
      </template>
      <template #default>
        <!-- <template v-for="(file, index) in files">
          <div
            :key="index"
            class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
            @click="$router.push({name: 'HSSOrderDetail', params: {orderId: order._id}})"
          >
            <div class="col-auto pr-2">
              <div class="order-icon-wrapper bg-secondary">
                <i class="far fa-file-contract fa-2x text-primary" />
              </div>
            </div>
            <div class="col">
              <h5 class="mb-0 d-inline">
                {{ `${index + 1}. ${order._id}` }}
              </h5>
            </div>
          </div>
        </template> -->
        <SignedDownloadLink
          v-for="(file, index) in files"
          :key="file._id"
          :file="file"
        >
          <template #default="{link}">
            <a
              class="row rounded-lg align-items-center cursor-pointer hover-gray-1 px-3 py-2"
              :href="link"
            >
              <div class="col-auto pr-2">
                <div class="order-icon-wrapper bg-secondary">
                  <i class="far fa-file-contract fa-2x text-primary" />
                </div>
              </div>
              <div class="col">
                <h5 class="mb-0 d-inline">
                  {{ `${index + 1}. ${file.name}` }}
                </h5>
              </div>
            </a>
          </template>
        </SignedDownloadLink>
      </template>
    </ca-card>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'

export default {
  components: {
    CaCard,
    SignedDownloadLink
  },
  filters: {
    formatDate
  },
  props: {
    files: {
      type: Array,
      required: true
    },
    title: {
      default: 'Dateien',
      type: String
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.order-icon-wrapper{
  margin-right: 5px;
  margin-bottom: 10px;
  border-radius: 50%;
  $size: 40px;
  width: $size;
  height: $size;
  position: relative;
  i{
    position: absolute;
    right: -5px;
    bottom: -5px;
  }
}

</style>
