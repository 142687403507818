<template>
  <div>
    <router-link
      class="btn btn-primary text-white mr-2"
      tag="button"
      :to="{ name: 'HSSOrderEdit', params: { orderId: orderId } }"
    >
      <i class="far fa-pen" />Anfrage bearbeiten
    </router-link>
    <button
      class="btn btn-orange text-white mr-2"
      type="button"
      @click="anfrageAblehnenModal = true"
    >
      Anfrage ablehnen
    </button>
    <button
      v-if="$can('orderAblaufenLassen')"
      class="btn btn-orange text-white mr-2"
      type="button"
      @click="showAbgelaufenModal = true"
    >
      Anfrage ablaufen lassen
    </button>
    <AnfrageAblehnenModal
      v-if="anfrageAblehnenModal"
      :order-id="orderId"
      @close="anfrageAblehnenModal = false"
    />
    <OrderAbgelaufenModal
      v-if="showAbgelaufenModal"
      title="Anfrage ablaufen lassen"
      message="Wollen Sie diese Anfrage wirklich ablaufen lassen?"
      button-text="Anfrage ablaufen lassen"
      :order-id="orderId"
      @close="showAbgelaufenModal = false"
    />
  </div>
</template>
<script>
import AnfrageAblehnenModal from '../Modals/AnfrageAblehnenModal.vue'
import OrderAbgelaufenModal from '../Modals/OrderAbgelaufenModal.vue'
export default {
  components: { AnfrageAblehnenModal, OrderAbgelaufenModal },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      anfrageAblehnenModal: false,
      showAbgelaufenModal: false
    }
  }
}
</script>
