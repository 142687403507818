<template>
  <div>
    <router-link
      class="btn btn-primary text-white mr-2"
      tag="button"
      :to="{ name: 'HSSOrderCreate', params: { orderId: orderId } }"
    >
      <i class="far fa-plus" /> Neue Variante zu diesem Angebot erstellen
    </router-link>
    <router-link
      class="btn btn-primary text-white mr-2"
      tag="button"
      :to="{ name: 'HSSOrderAngebotEdit', params: { orderId: orderId } }"
    >
      <i class="far fa-pen" /> Angebot annehmen
    </router-link>
    <button
      class="btn btn-orange text-white mr-2"
      type="button"
      @click="showAngebotAblehnenModal = true"
    >
      Angebot ablehnen
    </button>
    <button
      v-if="$can('orderAblaufenLassen')"
      class="btn btn-orange text-white mr-2"
      type="button"
      @click="showAbgelaufenModal = true"
    >
      Angebot ablaufen lassen
    </button>
    <AngebotAblehnenModal
      v-if="showAngebotAblehnenModal"
      :order-id="orderId"
      @close="showAngebotAblehnenModal = false"
    />
    <OrderAbgelaufenModal
      v-if="showAbgelaufenModal"
      title="Angebot ablaufen lassen"
      message="Wollen Sie dieses Angebot wirklich ablaufen lassen?"
      button-text="Angebot ablaufen lassen"
      :order-id="orderId"
      @close="showAbgelaufenModal = false"
    />
  </div>
</template>
<script>
import AngebotAblehnenModal from '../Modals/AngebotAblehnenModal.vue'
import OrderAbgelaufenModal from '../Modals/OrderAbgelaufenModal.vue'
export default {
  components: { AngebotAblehnenModal, OrderAbgelaufenModal },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showAngebotAblehnenModal: false,
      showAbgelaufenModal: false
    }
  }
}
</script>
