const abstellplaetze = require('../enums/abstellplaetze')
const fahrerkreise = require('../enums/fahrerkreise')
const hsOrderProducts = require('../enums/hsOrderProducts')
const kennzeichenArten = require('../enums/kennzeichenArten')
const laufleistung = require('../enums/laufleistung')
const nutzungen = require('../enums/nutzungen')
const selbstbehaltTK = require('../enums/selbstbehaltTK')
const selbstbehaltVK = require('../enums/selbstbehaltVK')
const weltweiterVersicherungsschutzBausteine = require('../enums/weltweiterVersicherungsschutzBausteine')
const wertnachweis = require('../enums/wertnachweis')
const wkz = require('../enums/wkz')
const zahlarten = require('../enums/zahlarten')
const zahlweisen = require('../enums/zahlweisen.js')
const zusatzpaketFreiesFahrenBausteine = require('../enums/zusatzpaketFreiesFahrenBausteine')
const fahrzeugZustand = require('../enums/fahrzeug-zustand')
const insuranceNewOrExisting = require('../enums/insuranceNewOrExisting')
const formatPrice = require('./formatPrice')
const dayjs = require('dayjs') // muss in dem Projekt installiert sein, wo dieser Helper verwendet wird.
const getFullNameFromCustomer = require('./getFullNameFromCustomer.js')
const months = require('../enums/months')
const calculateSaisondauer = require('./calculateSaisondauer')
const customerKindEnum = require('../enums/customerKindEnum')

module.exports = async function convertHSSOrderToTableData (order, customer, feathersClient) {
  let agent = null
  if (order.agentNumber) {
    const result = await feathersClient.service('users').find({
      query: {
        agentNumber: order.agentNumber
      }
    })
    agent = result.data[0]
  }
  // Mögliche Daten für Produkt, je nach dem welches Produkt gewählt wurde
  const alternateDataForProduct = {
    // Paket Kompakt
    [hsOrderProducts.KOMPAKT.api]: order.product === hsOrderProducts.KOMPAKT.api ? {
      label: hsOrderProducts.KOMPAKT.human,
      selbstbehalt: {
        label: 'Selbstbehalt',
        value: selbstbehaltTK[order.productPackages.KOMPAKT.selbstbehalt].human,
        prices: {
          priceTK: {
            label: 'Teilkasko Selbstbeteiligung',
            price: formatPrice(selbstbehaltTK[order.productPackages.KOMPAKT.selbstbehalt].tk)
          }
        }
      },
      schutzpacketFahrerschutz: {
        label: 'Schutzpaket Fahrerschutz',
        value: order.productPackages.KOMPAKT.schutzpacketFahrerschutz ? 'Ja' : 'Nein',
        price: order.productPackages.KOMPAKT.schutzpacketFahrerschutz ? formatPrice(order.calculatedData.schutzpacketFahrerschutzNettoZahlweise) : null
      }
    } : null,
    // Paket Komfort
    [hsOrderProducts.KOMFORT.api]: order.product === hsOrderProducts.KOMFORT.api ? {
      label: hsOrderProducts.KOMFORT.human,
      selbstbehalt: {
        label: 'Selbstbehalt',
        value: selbstbehaltVK[order.productPackages.KOMFORT.selbstbehalt].human,
        prices: {
          priceVK: {
            label: 'Vollkasko Selbstbeteiligung',
            price: formatPrice(selbstbehaltVK[order.productPackages.KOMFORT.selbstbehalt].vk)
          },
          priceTK: {
            label: 'inklusive Teilkasko Selbstbeteiligung',
            price: formatPrice(selbstbehaltVK[order.productPackages.KOMFORT.selbstbehalt].tk)
          }
        }
      },
      schutzpacketFahrerschutz: {
        label: 'Schutzpaket Fahrerschutz',
        value: order.productPackages.KOMFORT.schutzpacketFahrerschutz ? 'Ja' : 'Nein',
        price: order.productPackages.KOMFORT.schutzpacketFahrerschutz ? formatPrice(order.calculatedData.schutzpacketFahrerschutzNettoZahlweise) : null
      },
      plusVolllasko: {
        label: 'Zusatzpaket "Plus" ',
        value: order.productPackages.KOMFORT.plusVolllasko ? 'Ja' : 'Nein',
        price: order.productPackages.KOMFORT.plusVolllasko ? formatPrice(order.calculatedData.zusatzpaketPlusNettoZahlweise) : null
      },
      // zusatzpaketElektro: {
      //   label: 'Zusatzpaket Elektro (Old-/Youngtimer)',
      //   value: order.productPackages.KOMFORT.zusatzpacketElektro ? 'Ja' : 'Nein',
      //   price: order.productPackages.KOMFORT.zusatzpacketElektro ? formatPrice(order.calculatedData.zusatzpacketElektroNettoZahlweise) : null
      // },
      weltweiterVersicherungsschutzKasko: {
        label: 'Weltweiter Versicherungsschutz Kasko',
        value: weltweiterVersicherungsschutzBausteine[order.productPackages.KOMFORT.weltweiterVersicherungsschutzKasko].human,
        price: order.productPackages.KOMFORT.weltweiterVersicherungsschutzKasko !== 'no' ? formatPrice(order.calculatedData.weltweiterVersicherungsschutzNettoZahlweise) : null
      },
      zusatzpaketFreiesFahren: {
        label: 'Zusatzpaket Freies Fahren',
        value: zusatzpaketFreiesFahrenBausteine[order.productPackages.KOMFORT.zusatzpaketFreiesFahren].human,
        price: order.productPackages.KOMFORT.zusatzpaketFreiesFahren !== 'no' ? formatPrice(order.calculatedData.zusatzpaketFreiesFahrenNettoZahlweise) : null
      }
    } : null,
    // Paket Exclusiv
    [hsOrderProducts.EXCLUSIV.api]: order.product === hsOrderProducts.EXCLUSIV.api ? {
      label: hsOrderProducts.EXCLUSIV.human,
      selbstbehalt: {
        label: 'Selbstbehalt',
        value: selbstbehaltVK[order.productPackages.EXCLUSIV.selbstbehalt].human,
        prices: {
          priceVK: {
            label: 'Multirisk Selbstbeteiligung',
            price: formatPrice(selbstbehaltVK[order.productPackages.EXCLUSIV.selbstbehalt].vk)
          },
          priceTK: {
            label: 'Schäden aus dem Bereich Teilkasko Selbstbeteiligung',
            price: formatPrice(selbstbehaltVK[order.productPackages.EXCLUSIV.selbstbehalt].tk)
          }
        }
      },
      schutzpacketFahrerschutz: {
        label: 'Schutzpaket Fahrerschutz',
        value: order.productPackages.EXCLUSIV.schutzpacketFahrerschutz ? 'Ja' : 'Nein',
        price: order.productPackages.EXCLUSIV.schutzpacketFahrerschutz ? formatPrice(order.calculatedData.schutzpacketFahrerschutzNettoZahlweise) : null
      },
      plusVolllasko: {
        label: 'Zusatzpaket "Plus" ',
        value: order.productPackages.EXCLUSIV.plusVolllasko ? 'Ja' : 'Nein',
        price: order.productPackages.EXCLUSIV.plusVolllasko ? formatPrice(order.calculatedData.zusatzpaketPlusNettoZahlweise) : null

      },
      // zusatzpaketElektro: {
      //   label: 'Zusatzpaket Elektro (Old-/Youngtimer)',
      //   value: order.productPackages.EXCLUSIV.zusatzpacketElektro ? 'Ja' : 'Nein',
      //   price: order.productPackages.EXCLUSIV.zusatzpacketElektro ? formatPrice(order.calculatedData.zusatzpacketElektroNettoZahlweise) : null
      // },
      weltweiterVersicherungsschutzKasko: {
        label: 'Weltweiter Versicherungsschutz Kasko',
        value: weltweiterVersicherungsschutzBausteine[order.productPackages.EXCLUSIV.weltweiterVersicherungsschutzKasko].human,
        price: order.productPackages.EXCLUSIV.weltweiterVersicherungsschutzKasko !== 'no' ? formatPrice(order.calculatedData.weltweiterVersicherungsschutzNettoZahlweise) : null
      },
      zusatzpaketFreiesFahren: {
        label: 'Zusatzpaket Freies Fahren',
        value: zusatzpaketFreiesFahrenBausteine[order.productPackages.EXCLUSIV.zusatzpaketFreiesFahren].human,
        price: order.productPackages.EXCLUSIV.zusatzpaketFreiesFahren !== 'no' ? formatPrice(order.calculatedData.zusatzpaketFreiesFahrenNettoZahlweise) : null
      }
    } : null
  }
  // -----------------------------------------------------------------------------------------------------
  // HS-Order-Data tabellarisch aufgelistet
  const orderAsTableData = {
    // Fahrzeug
    carData: {
      label: 'Fahrzeug',
      kundenkontoSiteName: 'CarValueCondition',
      data: {
        fahrzeugName: {
          label: 'Fahrzeug',
          value: `${order.classicCar.Hersteller}  ${order.classicCar.Modellname} (${order.classicCar.BJ_von})`
        },
        erstzulassung: {
          label: 'Erstzulassung',
          value: order.carData.erstzulassung
        },
        motorstaerke: {
          label: 'Motorstärke',
          value: order.carData.motorstaerke
        },
        versicherungswert: {
          label: 'Versicherungswert',
          value: formatPrice(order.carData.versicherungswert)
        },
        wiederherstellungswertVersichern: {
          label: 'Wiederherstellungswert/Wiederaufbauwert versichern',
          value: order.wiederherstellungswertVersichern ? 'Ja' : 'Nein'
        },
        wiederherstellungswert: {
          label: 'Wiederherstellungswert/Wiederaufbauwert',
          value: order.wiederherstellungswertVersichern ? formatPrice(order.wiederherstellungswert) : null
        },
        gesamtversicherungswert: {
          label: 'Gesamtversicherungswert',
          value: order.wiederherstellungswertVersichern ? formatPrice(order.wiederherstellungswert + order.carData.versicherungswert) : null
        },
        zustand: {
          label: 'Fahrzeugzustand',
          value: fahrzeugZustand[order.carData.zustand].human
        },
        wkz: {
          label: 'Fahrzeugart',
          value: `${wkz[order.wkz].api} | ${wkz[order.wkz].human}`
        }
      }
    },
    // Diverses
    diversesData: {
      label: 'Weitere Daten',
      kundenkontoSiteName: 'CarAdditionalData',
      data: {
        nutzung: {
          label: 'Nutzung',
          value: nutzungen[order.carData.nutzung].human
        },
        laufleistung: {
          label: 'Laufleistung',
          value: laufleistung[order.carData.laufleistung].human
        },
        abstellplatzPlz: {
          label: 'PLZ / Ort Abstellplatz',
          value: order.abstellplatzPlz
        },
        abstellplatz: {
          label: 'Abstellplatz',
          value: abstellplaetze[order.carData.abstellplatz].human
        },
        fahrerkreis: {
          label: 'Fahrerkreis',
          value: fahrerkreise[order.fahrerkreis].human
        },
        geburtsdatumFahrerkreisMin: {
          label: 'Geburtsdatum des jüngsten Fahrers',
          value: fahrerkreise[order.fahrerkreis].human === fahrerkreise[1].human ? null : dayjs(order.geburtsdatumFahrerkreisMin).format('DD.MM.YYYY')
        },
        geburtsdatumFahrerkreisMax: {
          label: fahrerkreise[order.fahrerkreis].human === fahrerkreise[1].human ? 'Geburtstag des Versicherungsnehmers' : 'Geburtsdatum des ältesten Fahrers',
          value: dayjs(order.geburtsdatumFahrerkreisMax).format('DD.MM.YYYY')
        },
        kennzeichenart: {
          label: 'Kennzeichen Art',
          value: kennzeichenArten[order.carData.kennzeichenart].human
        },
        saisondauer: {
          label: 'Saisondauer',
          value: order.carData.kennzeichenart === 3 || order.carData.kennzeichenart === 4 ? `${months[order.carData.saisonkennzeichenvon].human} - ${months[order.carData.saisonkennzeichenbis].human} (${calculateSaisondauer(order.carData.saisonkennzeichenvon, order.carData.saisonkennzeichenbis)} Monate)` : null
        },
        vorschaeden: {
          label: 'Mehr als 2 selbstverschuldete Schäden in den vergangenen 3 Jahren',
          value: order.vorschaeden ? 'Ja' : 'Nein'
        }
      }
    },
    // Produkt
    productData: {
      label: 'Produkt',
      kundenkontoSiteName: 'CarRateSelection',
      data: {
        product: {
          label: 'Produkt',
          value: hsOrderProducts[order.product].fullName
        },
        ...alternateDataForProduct[order.product] // Nur die Zusatzpakete des ausgewählten Produkts anzeigen
      }
    },
    // Kunde / Versicherungsnehmer
    customerData: {
      label: 'Kunde / Versicherungsnehmer',
      kundenkontoSiteName: 'CarPersonalData',
      linkVerwaltung: {
        name: 'CustomerDetail',
        params: {
          customerId: customer._id
        }
      },
      data: {
        name: {
          label: 'Name',
          value: getFullNameFromCustomer(customer)
        },
        streetNr: {
          label: 'Straße, Nr.',
          value: customer.address.STR
        },
        zipCity: {
          label: 'PLZ / Ort',
          value: `${customer.address.PLZ} ${customer.address.ORT}`
        },
        // country: {
        //   label: 'Land',
        //   value: customer.country
        // }
        birthday: {
          label: 'Geburtstag',
          value: customer.kind === customerKindEnum.FIRMA.api ? null : customer.misc ? dayjs(customer.misc.GEBDAT).format('DD.MM.YYYY') : ''
        }
      }
    },
    // Weitere Daten
    otherData: {
      label: 'Diverses',
      kundenkontoSiteName: 'CarInsuranceData',
      data: {
        insuranceNewOrExisting: {
          label: 'Neuzulassung / Versicherungswechsel',
          value: order.carData.fahrzeugAuswahl ? insuranceNewOrExisting[order.carData.fahrzeugAuswahl].human : null
        },
        versicherungsbeginn: {
          label: 'Versicherungsbeginn',
          value: !order.versicherungsbeginn ? 'Tag der Zulassung' : dayjs(order.versicherungsbeginn).format('DD.MM.YYYY')
        },
        versicheurngsablauf: {
          label: 'Versicherungsablauf',
          value: !order.versicherungsbeginn ? 'Tag der Zulassung + 1 Jahr' : !order.versicherungsablauf ? dayjs(order.versicherungsbeginn).add(1, 'y').format('DD.MM.YYYY') : dayjs(order.versicherungsablauf).format('DD.MM.YYYY')
        },
        kennzeichen: {
          label: 'Kennzeichen',
          value: order.carData.kennzeichen
        },
        fahrzeugID: {
          label: 'Fahrzeugidentifikationsnummer',
          value: order.carData.fahrzeugID
        },
        kilometerstand: {
          label: 'Kilometerstand',
          value: order.carData.kilometerstand && order.carData.kilometerstand_datum ? `${order.carData.kilometerstand} km am ${dayjs(order.carData.kilometerstand_datum).format('DD.MM.YYYY')}` : null
        },
        wertnachweis: {
          label: 'Wertnachweis',
          value: order.wertnachweis ? `${wertnachweis[order.wertnachweis].human} vom ${dayjs(order.wertnachweisDatum).format('DD.MM.YYYY')}` : null
        },
        vermittlernummer: {
          label: 'Vermittlernummer',
          value: order.agentNumber
        },
        vermittlername: {
          label: 'Vermittlername',
          value: agent ? `${agent.firstname} ${agent.lastname}` : null
        }
      }
    },
    paymentData: {
      label: 'Zahldaten',
      kundenkontoSiteName: 'CarPayment',
      data: {
        zahlweise: {
          label: 'Zahlungsperiode',
          value: zahlweisen[order.zahlweise].human
        },
        zahlart: {
          label: 'Zahlart',
          value: order.zahlart ? zahlarten[order.zahlart].human : null
        },
        kontoinhaberName: {
          label: 'Kontoinhaber',
          value: order.zahlart !== zahlarten.SEPA.api ? null : order.isAbwKontoinhaber ? `${order.abwKontoinhaber.VOR} ${order.abwKontoinhaber.NAM}` : getFullNameFromCustomer(customer)
        },
        kontoinhaberStrHnr: {
          label: 'Straße, Nr.',
          value: order.zahlart === zahlarten.SEPA.api && order.isAbwKontoinhaber ? `${order.abwKontoinhaber.STR} ${order.abwKontoinhaber.HNR}` : customer.address.STR
        },
        kontoinhaberPlzOrt: {
          label: 'PLZ / Ort',
          value: order.zahlart === zahlarten.SEPA.api && order.isAbwKontoinhaber ? `${order.abwKontoinhaber.PLZ} ${order.abwKontoinhaber.ORT}` : `${customer.address.PLZ} ${customer.address.ORT}`
        },
        iban: {
          label: 'IBAN',
          value: order.zahlart === zahlarten.SEPA.api ? order.iban : null
        },
        bankname: {
          label: 'Kreditinstitut',
          value: order.zahlart === zahlarten.SEPA.api ? order.bankname : null
        }
      }
    }
  }
  const calculationData = {
    label: 'Deckungsumfang und Beitrag',
    data: {
      hinweisNetto: {
        label: ' ',
        value: 'Nettobetrag gem. Zahlungsperiode',
        valueClass: ['bold']
      },
      // Haftpflicht
      kfzHaftpflichtNetto: {
        label: 'Kfz-Haftpflichtversicherung',
        value: formatPrice(order.calculatedData.haftpflichtNettoZahlweise),
        labelClass: ['bold', 'borderTop']
      },
      hinweis: {
        label: '100 Mio. Euro pauschal für Personen-, Sach- und Vermögensschäden',
        subText: '(bei Personenschäden auf 15 Mio. Euro je geschädigte Person begrenzt)',
        value: ' '
      },
      fahrerschutz: {
        label: 'Inklusive Fahrerschutz',
        value: formatPrice(alternateDataForProduct[order.product].schutzpacketFahrerschutz.price)
      },
      // Kasko
      kaskoversicherung: {
        label: 'Kaskoversicherung',
        value: formatPrice(order.calculatedData.kaskoNettoZahlweise),
        labelClass: ['bold', 'borderTop']
      },
      vk: !alternateDataForProduct[order.product].selbstbehalt.prices.priceVK ? {} : {
        label: `${alternateDataForProduct[order.product].selbstbehalt.prices.priceVK.label} ${alternateDataForProduct[order.product].selbstbehalt.prices.priceVK.price}`,
        value: ' '
      },
      tk: {
        label: `${alternateDataForProduct[order.product].selbstbehalt.prices.priceTK.label} ${alternateDataForProduct[order.product].selbstbehalt.prices.priceTK.price}`,
        value: ' '
      },
      // Bausteine
      weltweiterVersicherungsschutzKasko: !alternateDataForProduct[order.product].weltweiterVersicherungsschutzKasko ? {} : {
        label: `${alternateDataForProduct[order.product].weltweiterVersicherungsschutzKasko.label} - ${alternateDataForProduct[order.product].weltweiterVersicherungsschutzKasko.value}`,
        value: formatPrice(alternateDataForProduct[order.product].weltweiterVersicherungsschutzKasko.price)
      },
      zusatzpaketFreiesFahren: !alternateDataForProduct[order.product].zusatzpaketFreiesFahren ? {} : {
        label: `${alternateDataForProduct[order.product].zusatzpaketFreiesFahren.label} - ${alternateDataForProduct[order.product].zusatzpaketFreiesFahren.value}`,
        value: formatPrice(alternateDataForProduct[order.product].zusatzpaketFreiesFahren.price)
      },
      plusVollkasko: !alternateDataForProduct[order.product].plusVolllasko ? {} : {
        label: `${alternateDataForProduct[order.product].plusVolllasko.label}`,
        value: formatPrice(alternateDataForProduct[order.product].plusVolllasko.price)
      },
      // zusatzpaketElektro: {
      //   label: `${alternateDataForProduct[order.product].zusatzpaketElektro.label}`,
      //   value: formatPrice(alternateDataForProduct[order.product].zusatzpaketElektro.price)
      // },
      nettoGesamtZahlweise: {
        label: 'Nettogesamtbeitrag gem. Zahlungsperiode',
        value: formatPrice(order.calculatedData.rateNetto),
        labelClass: ['borderTop']
      },
      versicherungssteuerZahlweise: {
        label: 'Versicherungsteuer zurzeit 19%',
        value: formatPrice(order.calculatedData.versicherungssteuerZahlweise)
      },
      bruttoGesamtZahlweise: {
        label: 'Bruttogesamtbeitrag gem. Zahlungsperiode',
        value: formatPrice(order.calculatedData.rateBrutto),
        labelClass: ['bold']
      }
    }
  }

  return {
    orderAsTableData,
    calculationData
  }
}
