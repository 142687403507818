<template>
  <Modal
    title="Wiederinkraftsetzung"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <BasicDatepicker
          v-model="ruhesetzungReaktivierungDatum"
          label="Datum der Anmeldung"
          required
          :disabled-dates="{from: dayjs().toDate()}"
        />
        <div class="form-row justify-content-end mt-5">
          <p
            v-if="error"
            class="text-danger w-100 text-right"
          >
            {{ error }}
          </p>
          <div>
            <button
              type="button"
              class="btn btn-secondary mr-2"
              @click="$emit('close')"
            >
              Abbrechen
            </button>
            <button
              type="button"
              class="btn btn-primary"
              :disabled="loading"
              @click="activateVertrag"
            >
              <i
                v-if="loading"
                class="fad fa-circle-notch fa-spin"
              />
              Wiederinkraftsetzung
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'
import BasicDatepicker from '../../components/BaseComponents/BasicDatepicker.vue'
import dayjs from 'dayjs'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicDatepicker
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ruhesetzungReaktivierungDatum: null,
      dayjs,
      loading: false,
      error: null
    }
  },
  methods: {
    async activateVertrag () {
      this.loading = true
      this.error = null
      if (!await this.$validator.validateAll()) return
      try {
        await feathersClient.service('hs-order-process-vertrag-aus-ruhe').create({
          hsOrderId: this.orderId,
          ruhesetzungReaktivierungDatum: this.ruhesetzungReaktivierungDatum
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.error = 'Es ist ein Fehler aufgetreten.'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
