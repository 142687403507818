const amsStatus = require('./amsStatus')

module.exports = {
  VNAblauf:
  {
    api: 'VNAblauf',
    human: 'Kündigung VN zum Ablauf',
    amsAPI: 'Kündigung VN zum Ablauf',
    anzeigeAnZulassungsstelleNach: '75Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  VNSchadenfallDifferenzen:
  {
    api: 'VNSchadenfallDifferenzen',
    human: 'Kündigung VN im Schadenfall / allgemeine Differenzen',
    amsAPI: 'Kündigung VN im Schadenfall / allgemeine Differenzen',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  VNBeitragserhoehungTarifaktion:
  {
    api: 'VNBeitragserhoehungTarifaktion',
    human: 'Kündigung VN bei Beitragserhöhung / Tarifaktion',
    amsAPI: 'Kündigung VN bei Beitragserhöhung / Tarifaktion',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  VUAblauf:
  {
    api: 'VUAblauf',
    human: 'Kündigung VU zum Ablauf',
    amsAPI: 'Kündigung VU zum Ablauf',
    anzeigeAnZulassungsstelleNach: '75Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  VUSchadenfall:
  {
    api: 'VUSchadenfall',
    human: 'Kündigung VU im Schadenfall',
    amsAPI: 'Kündigung VU im Schadenfall',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  VUBeitragszahlung:
  {
    api: 'VUBeitragszahlung',
    human: 'Kündigung VU mangels Beitragszahlung (Folgebeitrag § 38 VVG)',
    amsAPI: 'Kündigung VU mangels Beitragszahlung (Folgebeitrag § 38 VVG)',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  VUFahrzeugbewertung:
  {
    api: 'VUFahrzeugbewertung',
    human: 'Kündigung VU aufgrund fehlender Fahrzeugbewertung',
    amsAPI: 'sonstige Stornogründe',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  Risikofortfall:
  {
    api: 'Risikofortfall',
    human: 'Kündigung Risikofortfall (Besitzerwechsel) / Umzug ins Ausland / Totalschaden',
    amsAPI: 'Kündigung Risikofortfall (Besitzerwechsel) / Umzug ins Ausland / Totalschaden',
    anzeigeAnZulassungsstelleNach: 'Niemals',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  ErsatzversicherungUmstellung:
  {
    api: 'ErsatzversicherungUmstellung',
    human: 'Ersatzversicherung / Umstellung Einzelversicherung in Gruppenversicherung / Umstellung Gruppenversicherung in Einzelversicherung',
    amsAPI: 'Ersatzversicherung / Umstellung Einzelversicherung in Gruppenversicherung / Umstellung Gruppenversicherung in Einzelversicherung',
    anzeigeAnZulassungsstelleNach: 'Niemals',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  AblaufKurzfristig:
  {
    api: 'AblaufKurzfristig',
    human: 'Storno zum Ablauf kurzfristiger Verträge',
    amsAPI: 'Storno zum Ablauf kurzfristiger Verträge',
    anzeigeAnZulassungsstelleNach: 'Niemals',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  NichtInKraftGetreten:
  {
    api: 'NichtInKraftGetreten',
    human: 'nicht in Kraft getretener Vertrag / Storno ab Beginn (§ 37 VVG)',
    amsAPI: 'nicht in Kraft getretener Vertrag / Storno ab Beginn (§ 37 VVG)',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: false
  },
  Ruecktritt:
  {
    api: 'Ruecktritt',
    human: 'Rücktritt (z. B. Verletzung der vorvertraglichen Anzeigepflicht / Anfechtung)',
    amsAPI: 'Rücktritt (z. B. Verletzung der vorvertraglichen Anzeigepflicht / Anfechtung)',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  Tarifwechsel:
  {
    api: 'Tarifwechsel',
    human: 'Abgang nach Tarifwechsel',
    amsAPI: 'Abgang nach Tarifwechsel',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  Sonstige:
  {
    api: 'Sonstige',
    human: 'sonstige Stornogründe',
    amsAPI: 'sonstige Stornogründe',
    anzeigeAnZulassungsstelleNach: '3Tagen',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  Ruhe18Monate:
  {
    api: 'Ruhe18Monate',
    human: 'Ruhe länger als 18 Monate',
    amsAPI: 'Ruhe länger als 18 Monate',
    anzeigeAnZulassungsstelleNach: 'Niemals',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  },
  Doppelversicherung:
  {
    api: 'Doppelversicherung',
    human: 'Storno wg. Doppelversicherung',
    amsAPI: 'Storno wg. Doppelversicherung',
    anzeigeAnZulassungsstelleNach: 'Niemals',
    amsStatus: amsStatus.INFO.api,
    isReaktivierbar: true
  }
}
