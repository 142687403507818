<template>
  <div>
    <template v-if="showFreeInput">
      <BasicInput
        v-model="order.carData.kennzeichen"
        :required="required"
        label="Kennzeichen"
        :columns="{ label: ['col-12', 'col-md-4'], input: ['col-12', 'col-md-8'] }"
        horizontal
        placeholder
        :infotext="infotext"
      />
    </template>
    <template v-else>
      <div class="form-row">
        <div class="col-12 col-md-6">
          <BasicTypeahead
            id="unterscheidungskennzeichen"
            v-model="unterscheidungskennzeichen"
            name="Unterscheidungskennzeichen"
            placeholder="Stadt/Landkreis"
            :query="searchUnterscheidungkennzeichen"
            :input-formatter="unterscheidungskennzeichenInputFormatter"
            :result-formatter="unterscheidungskennzeichenResultFormatter"
            label="Kennzeichen"
            :columns="{ label: ['col-12', 'col-md-8'], input: ['col-12', 'col-md-4'] }"
            :margin="false"
            :horizontal="true"
            :required="required"
            strict
            :infotext="infotext"
            @input="setValue"
            @unselect="unselect"
          />
        </div>
        <div class="col-12 col-md-3">
          <BasicInput
            v-model="erkennungsZeichen"
            placeholder="Erkennungszeichen"
            name="Erkennungszeichen"
            :validation="{ regex: regexErkennungsZeichen }"
            :margin="false"
            :required="required"
            :disabled="order.carData.kennzeichenart === 7 || order.carData.kennzeichenart === 8"
          />
        </div>
        <div class="col-12 col-md-3">
          <BasicInput
            v-model="erkennungsNummer"
            placeholder="Erkennungsnummer"
            name="Erkennungsnummer"
            type="number"
            :margin="false"
            :validation="{ regex: regexErkennungsNummer }"
            :required="required"
          />
        </div>
        <div class="col-12">
          {{ regionInfo }}
        </div>
      </div>
      <div class="form-row mt-3">
        <label class="block col-12 col-md-4">Kennzeichenvorschau</label>
        <div class="col-12 col-md-8 flex-shrink-1">
          <div
            class="kennzeichenwrapper"
            :class="{ 'text-danger border-danger': order.carData.kennzeichenart === 7 || order.carData.kennzeichenart === 8, 'text-success border-success': order.carData.kennzeichenart === 9 }"
          >
            <div class="blue_banner">
              <div class="blue_banner-content-wrapper">
                <div class="euro-flag" />
                <div class="country-id">
                  D
                </div>
              </div>
            </div>
            <p
              class="charackter-wrapper"
              :class="{ 'w-8': !unterscheidungskennzeichen }"
            >
              {{ unterscheidungskennzeichen }}
            </p>
            <div class="badge-wrapper">
              <div class="badge-devider">
                <div class="top-badge" />
                <div class="bottom-badge" />
              </div>
            </div>
            <p
              class="charackter-wrapper"
              :class="{ 'w-8': !erkennungsZeichen }"
            >
              {{ erkennungsZeichen ? erkennungsZeichen.toUpperCase() : null }}
            </p>
            <p
              class="charackter-wrapper"
              :class="{ 'w-8': !erkennungsNummer }"
            >
              {{ erkennungsNummer }}{{ kennzeichenEndung }}
            </p>
            <div class="saison-wrapper">
              <div
                v-if="order.carData.kennzeichenart === 3 || order.carData.kennzeichenart === 4"
              >
                <div
                  class="saison-top"
                >
                  {{ order.carData.saisonkennzeichenvon ? String(order.carData.saisonkennzeichenvon).padStart(2, '0') : 'von' }}
                </div>
                <div
                  class="saison-bottom"
                >
                  {{ order.carData.saisonkennzeichenbis ? String(order.carData.saisonkennzeichenbis).padStart(2, '0') : 'bis' }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import feathersClient from '@/api'
import BasicInput from './BaseComponents/BasicInput.vue'
import BasicTypeahead from './BaseComponents/BasicTypeahead.vue'
export default {
  components: { BasicInput, BasicTypeahead },
  props: {
    order: {
      type: Object,
      required: true
    },
    required: {
      type: Boolean,
      default: true
    },
    infotext: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      erkennungsZeichen: null,
      erkennungsNummer: null,
      unterscheidungskennzeichen: null,
      unterscheidungskennzeichenObj: null
    }
  },
  computed: {
    showFreeInput () {
      const kennzeichenForFreeInput = [6, 11, 13, 14, 15]
      return kennzeichenForFreeInput.includes(this.order.carData.kennzeichenart)
    },
    kennzeichen () {
      if (!this.unterscheidungskennzeichen || !this.erkennungsZeichen || !this.erkennungsNummer) return null
      let kennzeichen = `${this.unterscheidungskennzeichen} ${this.erkennungsZeichen.toUpperCase()} ${this.erkennungsNummer}`
      if (this.kennzeichenEndung) kennzeichen = `${kennzeichen} ${this.kennzeichenEndung}`
      return kennzeichen
    },
    kennzeichenEndung () {
      switch (this.order.carData.kennzeichenart) {
      case 2:
      case 4:
        return 'H'
      case 12:
        return 'E'
      default: return null
      }
    },
    maxZeichen () {
      let max = 9
      // Zeichenzahl des Unterscheidungskennzeichens von der GesamtZahl abziehen
      if (this.unterscheidungskennzeichen) max -= this.unterscheidungskennzeichen.length
      const kennzeichenartenWith1Symbol = [2, 3, 12] // Diese Kennzeichen benötigen ein Zeichen
      if (kennzeichenartenWith1Symbol.includes(this.order.carData.kennzeichenart)) max--
      const kennzeichenartenWith2Symbols = [4] // Diese Kennzeichen benötigen zwei Zeichen
      if (kennzeichenartenWith2Symbols.includes(this.order.carData.kennzeichenart)) max = max - 2
      return max
    },
    regexErkennungsZeichen () {
      let maxZeichen = this.maxZeichen
      // Zeichenzahl der Erkennungsnummer von der Gesamtmenge der Zeichen abziehen
      if (this.erkennungsNummer) maxZeichen = this.maxZeichen - this.erkennungsNummer.length
      if (maxZeichen > 2) maxZeichen = 2 // Maximal 2 Erkennungszeichen
      if (maxZeichen < 1) maxZeichen = 1 // Mindestens 1 Erkennnungszeichen
      return new RegExp(`^[a-zA-Z]{1,${maxZeichen}}$`)
    },
    regexErkennungsNummer () {
      let maxZeichen = this.maxZeichen
      // Zeichenzahl der Erkennungszeichen von der Gesamtmenge der Zeichen abziehen
      if (this.erkennungsZeichen) maxZeichen = this.maxZeichen - this.erkennungsZeichen.length
      if (maxZeichen > 4) maxZeichen = 4 // Maximal 4 Erkennnungsnummern
      if (maxZeichen < 1) maxZeichen = 1 // Mindestens 1 Erkennnungsnummer
      maxZeichen-- // 1 Zeichen abziehen, da das erste Zeichen im Regex gesondert geregelt ist.
      if (this.order.carData.kennzeichenart === 7 || this.order.carData.kennzeichenart === 8) {
        return new RegExp(`^[0-9]{1}[0-9]{0,${maxZeichen}}$`)
      }
      return new RegExp(`^[1-9]{1}[0-9]{0,${maxZeichen}}$`)
    },
    regionInfo () {
      if (!this.unterscheidungskennzeichenObj) return null
      return `${this.unterscheidungskennzeichenObj.stadt_landkreis}, ${this.unterscheidungskennzeichenObj.bundesland}`
    }
  },
  watch: {
    'order.carData.kennzeichenart': {
      handler: function (newVal, oldVal) {
        if (oldVal === 7 || oldVal === 8) this.erkennungsZeichen = null
        if (this.order.carData.kennzeichenart === 7) this.erkennungsZeichen = '07'
        if (this.order.carData.kennzeichenart === 8) this.erkennungsZeichen = '06'
        if (this.order.carData.kennzeichenart === 10) {
          this.$set(this.order.carData, 'kennzeichen', undefined)
          this.$set(this.order.carData, 'kennzeichenGDVFormat', undefined)
          this.erkennungsZeichen = null
          this.erkennungsNummer = null
          this.unterscheidungskennzeichen = null
          this.unterscheidungskennzeichenObj = null
        }
      },
      immediate: true
    },
    kennzeichen: {
      handler: function () {
        this.$set(this.order.carData, 'kennzeichen', this.kennzeichen)
        if (this.kennzeichen) {
          this.$set(this.order.carData, 'kennzeichenGDVFormat', `${this.unterscheidungskennzeichen.padEnd(3, ' ')}${this.erkennungsZeichen.toUpperCase().padEnd(2, ' ')}${String(this.erkennungsNummer).padStart(4, ' ')}`)
        } else this.$set(this.order.carData, 'kennzeichenGDVFormat', null)
      }
    }
  },
  async created () {
    if (!this.showFreeInput && this.order.carData.kennzeichen && this.order.carData.kennzeichen.split(' ').length >= 2) {
      const kennzeichenParts = this.order.carData.kennzeichen.split(' ')
      const result = await feathersClient.service('hsdata-unterscheidungskennzeichen').find({
        query: {
          $limit: 50,
          unterscheidungskennzeichen: kennzeichenParts[0]
        }
      })
      this.unterscheidungskennzeichenObj = result.data[0]
      this.unterscheidungskennzeichen = this.unterscheidungskennzeichenObj.unterscheidungskennzeichen
      this.erkennungsZeichen = kennzeichenParts[1]
      this.erkennungsNummer = kennzeichenParts[2]
    }
  },
  methods: {
    async searchUnterscheidungkennzeichen (searchTerm) {
      let result = await feathersClient.service('hsdata-unterscheidungskennzeichen').find({
        query: {
          $limit: 50,
          $or: [
            { unterscheidungskennzeichen: { $regex: '^' + searchTerm, $options: 'i' } }
          ]
        }
      })
      result = result.data.map(unterscheidungskennzeichenObj => {
        return { ...unterscheidungskennzeichenObj, value: unterscheidungskennzeichenObj.unterscheidungskennzeichen }
      })
      return result
    },
    unterscheidungskennzeichenInputFormatter (input) {
      if (typeof input === 'object') {
        return input.unterscheidungskennzeichen
      }
      return input
    },
    unterscheidungskennzeichenResultFormatter (unterscheidungskennzeichenObj) {
      return unterscheidungskennzeichenObj.unterscheidungskennzeichen
    },
    setValue (event) {
      if (typeof event === 'object') {
        this.unterscheidungskennzeichen = event.value
        this.unterscheidungskennzeichenObj = event
      }
      return event
    },
    unselect () {
      this.unterscheidungskennzeichen = null
      this.unterscheidungskennzeichenObj = null
    }
  }
}
</script>
<style lang="scss" scoped>
.kennzeichenwrapper {
  font-weight: 700 !important;
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
  font-family: Courier New !important;
  border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
  border-width: 1px !important;
  border-radius: 0.25rem !important;
  display: inline-flex !important;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: currentColor;
}
.blue_banner {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
  --tw-bg-opacity: 1 !important;
  background-color: rgba(0, 0, 255, var(--tw-bg-opacity)) !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}
.blue_banner-content-wrapper {
  align-items: center !important;
  flex-direction: column !important;
  display: flex !important;
}
.euro-flag {
  --tw-border-opacity: 1 !important;
  border-color: rgba(252, 211, 77, var(--tw-border-opacity)) !important;
  border-style: dotted !important;
  border-width: 2px !important;
  border-radius: 9999px !important;
  width: 0.75rem !important;
  height: 0.75rem !important;
}
.country-id {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
  font-weight: 100 !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.charackter-wrapper {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  align-self: center !important;
  margin-bottom: 0px !important;
  margin: 0;
}

.badge-wrapper {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
  justify-content: center !important;
  align-items: center !important;
  display: flex !important;
}

.badge-devider {
  align-items: center !important;
  flex-direction: column !important;
  display: flex !important;
}

.top-badge {
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
    border-width: 1px !important;
    border-radius: 9999px !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
    border-style: solid;
}

.bottom-badge {
    --tw-space-y-reverse: 0 !important;
    margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
    margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
    border-width: 1px !important;
    border-radius: 9999px !important;
    width: 0.75rem !important;
    height: 0.75rem !important;
    border-style: solid;
}
.saison-wrapper {
    --tw-space-x-reverse: 0 !important;
    margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
    margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
    padding-right: 0.375rem !important;
    align-self: center !important;
    display: flex !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}
.saison-top {
    --tw-border-opacity: 1 !important;
    border-color: rgba(0, 0, 0, var(--tw-border-opacity)) !important;
    border-bottom-width: 1px !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 50% !important;
    display: flex !important;
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
}
.saison-bottom {
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
    height: 50% !important;
    display: flex !important;
}
.w-8 {
   width: 2rem;
}
</style>
