<template>
  <Modal
    title="eVB erzeugen"
    size="lg"
    max-height
    @close="$emit('close')"
  >
    <template #body>
      <form class="h-100 overflow-auto">
        <CustomerForm
          :customer="editableCustomer"
          is-new-customer
          heading="Versicherungsnehmer"
        />
        <Card>
          <template #header>
            <h4 class="mb-0">
              Kennzeichen + Fahrzeug
            </h4>
          </template>
          <template #body>
            <BasicSelect
              v-model="editableOrder.carData.kennzeichenart"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              label="Kennzeichen Art"
              required
              :options="kennzeichenArten"
              placeholder="Bitte auswählen"
            />

            <Saisondauer
              v-if="editableOrder.carData.kennzeichenart === 3 || editableOrder.carData.kennzeichenart === 4"
              :saisonkennzeichenvon="editableOrder.carData.saisonkennzeichenvon"
              :saisonkennzeichenbis="editableOrder.carData.saisonkennzeichenbis"
              @update:saisonkennzeichenvon="$set(editableOrder.carData, 'saisonkennzeichenvon', $event)"
              @update:saisonkennzeichenbis="$set(editableOrder.carData, 'saisonkennzeichenbis', $event)"
            />
            <LicensePlateInput
              :order="editableOrder"
              :required="false"
            />
            <BasicInput
              key="4"
              v-model="editableOrder.carData.fahrzeugID"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              horizontal
              label="Fahrzeugidentifikationsnummer"
              placeholder="Fahrzeugidentifikationsnummer"
              :validation="{alpha_num: true}"
            />
          </template>
        </Card>
      </form>
    </template>
    <template #footer>
      <p
        v-if="showSuccessMessage"
        class="text-primary"
      >
        Die eVB wurde erfolgreich erzeugt, Sie erhalten in Kürze eine E-Mail mit der eVB-Nummer.
        <br>
        Die eVB-Nummer lautet: {{ evbNummer }}
      </p>
      <p
        v-if="errorMessage"
        class="text-danger"
      >
        Die eVB konnte nicht erzeugt werden: {{ errorMessage }}
      </p>
      <div class="form-row justify-content-end">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          @click="$emit('close')"
        >
          Abbrechen
        </button>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="loading"
          @click="createEvb"
        >
          <i
            v-if="loading"
            class="far fa-spin fa-circle-notch mr-1"
          /> eVB erzeugen
        </button>
      </div>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'
import cloneDeep from 'lodash/cloneDeep'
import CustomerForm from '../Forms/Customer/CustomerForm.vue'
import Card from '../Card.vue'
import BasicSelect from '../../components/BaseComponents/BasicSelect.vue'
import BasicInput from '../../components/BaseComponents/BasicInput.vue'
import kennzeichenArten from '@/resources/enums/kennzeichenArten'
import Saisondauer from '../Forms/Order/Saisondauer.vue'
import LicensePlateInput from '../LicensePlateInput.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    CustomerForm,
    Card,
    BasicSelect,
    Saisondauer,
    LicensePlateInput,
    BasicInput
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    customer: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      editableCustomer: {},
      editableOrder: {
        carData: {}
      },
      loading: false,
      showSuccessMessage: false,
      errorMessage: null,
      evbNummer: null
    }
  },
  computed: {
    kennzeichenArten () {
      return Object.values(kennzeichenArten).map(art => ({ value: art.api, label: art.human }))
    }
  },
  watch: {
    customer: {
      handler: function () {
        this.editableCustomer = cloneDeep(this.customer)
      },
      immediate: true
    },
    order: {
      handler: function () {
        this.editableOrder = cloneDeep(this.order)
      },
      immediate: true
    }
  },
  methods: {
    async createEvb () {
      this.loading = true
      this.showSuccessMessage = false
      this.errorMessage = null
      this.evbNummer = null
      try {
        this.evbNummer = await feathersClient.service('hs-order-process-manuelle-evb').create({
          hsOrder: this.editableOrder,
          customer: this.editableCustomer
        })
        this.showSuccessMessage = true
      } catch (error) {
        console.error(error)
        this.errorMessage = error.message
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
