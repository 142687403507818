<template>
  <Modal
    :title="title"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <p>{{ message }}</p>
        <p
          v-if="errorMessage"
          class="text-danger text-right mb-0"
        >
          {{ errorMessage }}
        </p>
        <div class="form-row justify-content-end">
          <button
            type="button"
            class="btn btn-secondary mr-2"
            @click="$emit('close')"
          >
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-orange"
            @click="expireOrder"
          >
            <i
              v-if="loading"
              class="far fa-spin fa-circle-notch mr-1"
            />{{ buttonText }}
          </button>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'

export default {
  components: {
    Modal
  },
  props: {
    orderId: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      errorMessage: null
    }
  },
  methods: {
    async expireOrder () {
      try {
        this.loading = true
        this.errorMessage = null
        await feathersClient.service('hs-order-process-order-abgelaufen').create({
          hsOrderId: this.orderId
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.errorMessage = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
