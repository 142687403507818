<template>
  <div>
    <ca-header
      :heading="getHeading"
      :tag="order && order.vertragAutomatischErstellt ? 'Automatisch erzeugt' : ''"
      :loading="loading"
    >
      <template #bottom>
        <div
          v-if="order && order.hsOrderStatus"
          class="d-flex w-full"
        >
          <ProcessAnfrageHeader
            v-if="$can('processAnfragen') && order.hsOrderStatus === hsOrderStatus.AKTIV.api && order.hsOrderType === hsOrderTypes.ANFRAGE.api"
            :order-id="order._id"
          />
          <ProcessAngebotHeader
            v-if="$can('processAngebote') && order.hsOrderStatus === hsOrderStatus.AKTIV.api && order.hsOrderType === hsOrderTypes.ANGEBOT.api"
            :order-id="order._id"
          />
          <ProcessAntragHeader
            v-if="$can('processAntraege') && order.hsOrderStatus === hsOrderStatus.AKTIV.api && order.hsOrderType === hsOrderTypes.ANTRAG.api"
            :order-id="order._id"
          />
          <ProcessVertragHeader
            v-if="order.hsOrderType === hsOrderTypes.VERTRAG.api"
            :order="order"
            :customer="customer"
          />
          <ProcessNachtragHeader
            v-if="$can('processNachtrag') && client && [hsOrderStatus.AKTIV.api, hsOrderStatus.RUHEND.api, hsOrderStatus.STORNO_VORGEMERKT.api, hsOrderStatus.STORNIERT.api].includes(order.hsOrderStatus) && order.hsOrderType === hsOrderTypes.VERTRAG.api"
            :order="order"
            :client="client"
          />
          <router-link
            v-if="$can('addWiedervorlagen')"
            class="btn btn-primary"
            :to="{name: 'ResubmitCreate', params: {hsOrderId: order._id} }"
          >
            Neue Wiedervorlage erstellen
          </router-link>
        </div>
      </template>
    </ca-header>
    <button
      v-if="isTestsystem"
      @click="expireVertrag"
    >
      Vertrag ablaufen lassen
    </button>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <DetailData
            v-if="order"
            :order="order"
            :loading="loading"
            :customer="customer"
            class="mb-3"
          />
          <GdvFehlermeldungen
            v-if="order.gdvFehlermeldungen && order.gdvFehlermeldungen.length"
            :order="order"
            class="mb-3"
          />
          <Comments
            v-if="order && ($can('comments') || $can('commentsIntern'))"
            entity="hsorder"
            :entity-id="order._id"
          />
        </div>
        <div
          class="col-12 col-xl-4 col-xxl-3"
        >
          <RelatedFiles
            v-if="order && order.files"
            :files="order.files"
            title="Weitere Dokumente"
          />
          <RelatedFiles
            v-if="order.wertnachweisFiles"
            :files="order.wertnachweisFiles"
            title="Wertnachweis"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import hsOrderStatus from '@/resources/enums/hsOrderStatus.js'
import hsOrderTypes from '@/resources/enums/hsOrderTypes.js'

import CaHeader from '@/components/Layout/Header'
import ProcessAnfrageHeader from '@/components/HsOrderProcesses/ProcessAnfrageHeader.vue'
import ProcessAngebotHeader from '@/components/HsOrderProcesses/ProcessAngebotHeader.vue'
import DetailData from './components/DetailData.vue'
import formatOrderStatus from '@/filters/formatOrderStatus'
import ProcessAntragHeader from '@/components/HsOrderProcesses/ProcessAntragHeader.vue'
import ProcessVertragHeader from '@/components/HsOrderProcesses/ProcessVertragHeader.vue'
import ProcessNachtragHeader from '@/components/HsOrderProcesses/ProcessNachtragHeader.vue'
import dayjs from 'dayjs'
import feathersClient from '@/api'
import Comments from '@/components/Comments.vue'
import GdvFehlermeldungen from './components/GdvFehlermeldungen.vue'
import RelatedFiles from '@/components/RelatedFiles.vue'

export default {
  name: 'HSSOrderDetail',
  components: {
    CaHeader,
    ProcessAnfrageHeader,
    ProcessAngebotHeader,
    DetailData,
    ProcessAntragHeader,
    ProcessVertragHeader,
    ProcessNachtragHeader,
    Comments,
    GdvFehlermeldungen,
    RelatedFiles
  },
  data () {
    return {
      order: null,
      client: null,
      customer: {},
      loading: false,
      hsOrderStatus,
      hsOrderTypes
    }
  },
  computed: {
    getHeading () {
      if (!this.order || (!this.order.hsOrderType && this.order.hsOrderType !== 0) || !this.order.number) return ''
      return `${formatOrderStatus(this.order.hsOrderType)} ${this.order.number} ${this.order.hsOrderStatus}`
    },
    isTestsystem () {
      return process.env.NODE_ENV === 'staging' || process.env.NODE_ENV === 'development'
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
        this.order = await this.$store.dispatch('hs-order/get', this.$route.params.orderId)
        this.customer = await this.$store.dispatch('customer/get', this.order.customerId)
        if (this.customer.address.NAT) {
          this.customerCountry = (await this.$store.dispatch('countries/get', this.customer.address.NAT)).name
        }
        if (!this.customer.misc) this.$set(this.customer, 'misc', {})
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    async expireVertrag () {
      try {
        const patchOrder = await feathersClient.service('hs-order').patch(this.order._id, { versicherungsablauf: dayjs().subtract(1, 'day').toDate() }, { query: { $client: { noPDF: true, noCalculation: true } } })
        console.log(patchOrder.versicherungsablauf)
        this.fetchData()
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.tabs ::v-deep {
  .tab-pane:focus {
    outline: none;
  }
}
</style>
