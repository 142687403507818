<template>
  <Modal
    title="Vertrag stornieren"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <BasicDatepicker
          v-model="stornoDatum"
          label="Stornodatum"
          required
        />
        <BasicSelect
          v-model="stornoGrund"
          label="Stornogrund"
          :options="stornogruendeOptions"
          required
        />
        <div class="form-row justify-content-end">
          <p
            v-if="error"
            class="text-danger w-100 text-right"
          >
            {{ error }}
          </p>
          <div>
            <button
              type="button"
              class="btn btn-secondary mr-2"
              @click="$emit('close')"
            >
              Abbrechen
            </button>
            <button
              type="button"
              class="btn btn-orange"
              :disabled="loading"
              @click="cancelVertrag"
            >
              <i
                v-if="loading"
                class="fad fa-circle-notch fa-spin"
              />
              Vertrag stornieren
            </button>
          </div>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'
import BasicDatepicker from '../../components/BaseComponents/BasicDatepicker.vue'
import BasicSelect from '../../components/BaseComponents/BasicSelect.vue'
import stornogruende from '@/resources/enums/stornogruende.js'
import dayjs from 'dayjs'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicDatepicker,
    BasicSelect
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      stornoDatum: dayjs().toDate(),
      stornoGrund: null,
      loading: false,
      error: null
    }
  },
  computed: {
    stornogruendeOptions () {
      return Object.values(stornogruende).map(grund => ({ value: grund.api, label: grund.human }))
    }
  },
  methods: {
    async cancelVertrag () {
      this.loading = true
      this.error = null
      if (!await this.$validator.validateAll()) return
      try {
        await feathersClient.service('hs-order-process-vertrag-stornieren').create({
          hsOrderId: this.orderId,
          stornoDatum: this.stornoDatum,
          stornoGrund: this.stornoGrund
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.error = 'Es ist ein Fehler aufgetreten.'
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
