<template>
  <Modal
    title="Abschrift erzeugen"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        <p>Erzeugen Sie eine Abschrift des Vertrages {{ order.number }}. </p>
        <p> Bei einer Abschrift wird ein Vertragsdokument mit den aktuellen Vertragsdaten und dem korrekten Ablaufdatum erzeugt.</p>
        <p class="text-danger">
          {{ errorMessage }}
        </p>
        <div class="form-row justify-content-end">
          <button
            type="button"
            class="btn btn-secondary mr-2"
            @click="$emit('close')"
          >
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="createAbschrift"
          >
            <i
              v-if="loading"
              class="fas fa-circle-notch fa-spin"
            />
            Abschrift erzeugen
          </button>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import Modal from '../Modal.vue'
import feathersClient from '@/api'
import stornogruende from '@/resources/enums/stornogruende.js'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      errorMessage: null,
      loading: false
    }
  },
  computed: {
    stornogruendeOptions () {
      return Object.values(stornogruende).map(grund => ({ value: grund.api, label: grund.human }))
    }
  },
  methods: {
    async createAbschrift () {
      try {
        this.loading = true
        await feathersClient.service('hs-order-process-vertrag-abschrift').create({
          hsOrderId: this.order._id
        })
        this.$emit('close')
      } catch (error) {
        console.error(error)
        this.errorMessage = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
